<template lang="html">
  <div id="thank-you-page">

    <section class="container main-section">
      <div class="row">
        <div class="col-lg-10 col-intro">
          <p class="text-center">
            <img src="public/images/pages/cart/check.png">
          </p>

          <h6 class="text-center title-1">THANK YOU</h6>

          <h2 class="text-center title-s1">Payment completed</h2>

          <hr />

          <h4 class="text-center title-2">Get ready to create memories that will last a lifetime. Save travels!</h4>
        </div>

        <div class="col-lg-9 col-ticket">
          <div class="box-ticket">
            <div class="row row-top">
              <div class="col-5 col-sm-6 col-logo">
                <img class="icon-check" src="public/images/logo.png">
              </div>

              <div class="col-7 col-sm-6 col-rnumber">
                <h6 class="txt-label-1">Reservation number</h6>
                <h5 class="txt-result">A221237800 243</h5>
              </div>
            </div>

            <div class="row row-main-info">
              <div class="col-12 col-name">
                <h6 class="txt-label-1">Your name</h6>
                <h5 class="name">Exclusive private tour at Cozumel</h5>
              </div>

              <div class="col-lg-4 col-qr">
                <img src="public/images/pages/cart/qr.png">
              </div>

              <div class="col-lg-8 col-xl-7 offset-xl-1 col-information">
                <div class="row row-wr">
                  <div class="col-sm-6 col-lg-4 col-info">
                    <h6 class="txt-label-1">Adult tickets</h6>
                    <h6 class="txt-result">2 Adults</h6>
                  </div>

                  <div class="col-sm-6 col-lg-4 col-info">
                    <h6 class="txt-label-1">Kids tickets</h6>
                    <h6 class="txt-result">--</h6>
                  </div>

                  <div class="col-lg-4 col-info d-none d-lg-inline-block"></div>

                  <div class="col-md-4 col-lg-4 col-info">
                    <h6 class="txt-label-1">Date</h6>
                    <h6 class="txt-result">July 25, 2024</h6>
                  </div>

                  <div class="col-6 col-md-4 col-lg-4 col-info">
                    <h6 class="txt-label-1">Departs</h6>
                    <h6 class="txt-result">05:30 AM</h6>
                  </div>

                  <div class="col-6 col-md-4 col-lg-4 col-info">
                    <h6 class="txt-label-1">Return</h6>
                    <h6 class="txt-result">07:45 PM</h6>
                  </div>
                </div>

                <div class="row row-wr">
                  <div class="col-12 col-info">
                    <h6 class="txt-label-1">Name reservation</h6>
                    <h6 class="txt-result">Jesús Torres Amezcua</h6>
                  </div>

                  <div class="col-sm-7 col-info">
                    <h6 class="txt-label-1">Email address</h6>
                    <h6 class="txt-result">jesus@email.com</h6>
                  </div>

                  <div class="col-sm-5 col-info">
                    <h6 class="txt-label-1">Phone number</h6>
                    <h6 class="txt-result">+52 333 123 4567</h6>
                  </div>
                </div>

                <div class="row row-wr">
                  <div class="col-sm-4 col-lg-4 col-info">
                    <h6 class="txt-label-1">Tax and service fees</h6>
                    <h6 class="txt-result">$8.32</h6>
                  </div>

                  <div class="col-sm-4 col-lg-4 col-info">
                    <h6 class="txt-label-1">Promo code</h6>
                    <h6 class="txt-result">--</h6>
                  </div>

                  <div class="col-sm-4 col-lg-4 col-info">
                    <h6 class="txt-label-1">Tota</h6>
                    <h6 class="txt-result">
                      <span class="total">$130.00</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="row row-extra-info">
              <div class="col-12 col-info">
                <h6 class="title">Important information about your booking</h6>

                <ol>
                  <li>This rate is non-refundable. If you change or cancel your booking you will not get a refund or credit to use for a future stay.</li>
                  <li>Stay extensions will require a new reservation.</li>
                  <li>﻿﻿﻿Front desk staff will greet guests on arrival.</li>
                  <li>﻿﻿﻿No refunds will be issued for late check-in or early check-out.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
