<template lang="html">
  <section class="reviews-component">
    <div class="container">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="subtitle">TESTIMONIALS</h5>
          <h2 class="title-s1">See what our clients say<br />About us</h2>
        </div>

        <div class="col-12 col-lg-8 mx-auto col-testimonials">
          <swiper class="swiper" :options="testimonialsOptions">
            <swiper-slide v-for="(t, tshInx) in 3" :key="'tshInx-'+tshInx">
              <div class="box-testimonial">
                <div class="box">
                  <div class="b-photo">
                    <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/testimonial-photo.jpg)' }"></div>
                  </div>

                  <div class="b-text">
                    <div class="inside">
                      <p>
                        <strong class="apos">“</strong>Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat. Id sunt provident quo possimus impedit vel doloreque obcaecati qui ullam consectetur et ipsum omnis.<strong class="apos">”</strong>
                      </p>
                      <h6 class="author">Christine Beckam - Designer</h6>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      // == Carousels options ==
      testimonialsOptions: {
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
      // == ==
    }
  }
}
</script>
