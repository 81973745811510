<template lang="html">
  <div id="tour-description-page">

    <section class="banners-section">
      <swiper class="swiper" :options="bannersOptions">
        <swiper-slide>
          <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/tours/banner-1.jpg)' }"></div>
        </swiper-slide>
      </swiper>

      <div class="box-content">
        <div class="container">
          <div class="row">
            <div class="col-12 col-content">
              <h6 class="title-1">EXPLORE</h6>
              <h3 class="name">Cozumel</h3>
              <h4 class="title-2">Private boat + snorkel in 2 islands</h4>

              <div class="box-buttons">
                <router-link class="btn btn-s1" to="/booking">Check availability</router-link>
                <router-link class="btn btn-s2" to="/booking">Book now!</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-content">
            <div class="box-content">
              <div class="box-breadcrumb">
                <router-link class="t-150 link-back" to=""><i class="fas fa-chevron-left icon"></i> EXCLUSIVE PRIVATE TOUR AT COZUMEL</router-link>
              </div>

              <div class="box-short-info">
                <h3 class="name">Cozumel</h3>
                <div class="b-price">
                  <h4 class="price">1,000 $</h4>
                  <span class="symbol">/</span>
                  <span class="txt">Per Couple</span>
                </div>

                <div class="b-stars">
                  <b-form-rating inline value="4" disabled></b-form-rating>
                  <span class="txt">(2.3k review)</span>
                </div>
              </div>

              <div class="row box-main-content">
                <div class="col-xl-7 col-information">
                  <div class="b-info">
                    <p>
                      Ex optio sequi et quos praesentium in nostrum labore nam rerum iusto aut magni nesciunt? Quo quidem neque iste expedita est dolor similique ut quasi maxime ut deserunt autem At praesentium voluptatem aut libero nisi. Et eligendi sint ab cumque veritatis aut provident aliquam. Aut aspernatur consequuntur eum quaerat distinctio ut inventore aliquid et quasi alias ut rerum suscipit et nihil deleniti. Ex optio sequi et quos praesentium in nostrum labore nam rerum iusto aut magni nesciunt? Quo quidem neque iste expedita est dolor similique ut quasi maxime ut deserunt autem At praesentium voluptatem aut libero nisi. Et eligendi sint ab cumque veritatis aut provident aliquam. Aut aspernatur consequuntur eum quaerat distinctio ut inventore aliquid et quasi alias ut rerum suscipit et nihil deleniti.
                    </p>
                  </div>

                  <div class="box-table">
                    <div class="row">
                      <div class="col col-lab">Destination</div>
                      <div class="col col-info">Isla Mujeres, Cozumel</div>
                    </div>

                    <div class="row">
                      <div class="col col-lab">Departure</div>
                      <div class="col col-info">Hotel, Cozumel</div>
                    </div>

                    <div class="row">
                      <div class="col col-lab">Departure time</div>
                      <div class="col col-info">Approximately 08:10 AM</div>
                    </div>

                    <div class="row">
                      <div class="col col-lab">Return time</div>
                      <div class="col col-info">Approximately 07:20 PM</div>
                    </div>

                    <div class="row">
                      <div class="col col-lab">Dress code</div>
                      <div class="col col-info">Bathsuit, comfortable and light</div>
                    </div>

                    <div class="row">
                      <div class="col col-lab">Not included</div>
                      <div class="col col-info">
                        <div class="b-tag"><i class="far fa-times-circle icon"></i> Alcohol beverages</div>
                        <div class="b-tag"><i class="far fa-times-circle icon"></i> Lunch</div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col col-lab">Included</div>
                      <div class="col col-info">
                        <div class="b-tag"><i class="far fa-check-circle icon"></i> 5 star accomodations</div>
                        <div class="b-tag"><i class="far fa-check-circle icon"></i> Airport transfer</div>
                        <div class="b-tag"><i class="far fa-check-circle icon"></i> Breakfast</div>
                        <div class="b-tag"><i class="far fa-check-circle icon"></i> Personal guide</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-5 col-faqs">
                  <div class="box">
                    <h5 class="title">F.A.Q.</h5>

                    <div class="accordion" role="tablist">
                      <b-card no-body v-for="(f, fInx) in faqs" :key="'fInx-'+fInx">
                        <b-card-header header-tag="header" role="tab">
                          <a v-b-toggle="'accordion-'+fInx">Lorem ipsum dolor sit amet consectetur. Lorem eget diam a justo. Tellus nunc scelerisque ut ut sollicitudin integer porta amet.</a>
                        </b-card-header>

                        <b-collapse :id="'accordion-'+fInx" accordion="accordion-faqs" role="tabpanel">
                          <b-card-body>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>

                  <div class="box-buttons">
                    <router-link class="btn btn-s1 s-2" to="/booking">Check availability</router-link>
                    <router-link class="btn btn-s2" to="/booking">Book now!</router-link>
                  </div>
                </div>

                <div class="col-xl-7 col-gallery">
                  <h3 class="title-s2">From our gallery</h3>

                  <div class="b-description">
                    <p>
                      Ex optio sequi et quos praesentium in nostrum labore nam rerum iusto aut magni nesciunt? Quo quidem neque iste expedita est dolor similique ut quasi maxime ut deserunt autem At praesentium voluptatem aut libero nisi.
                    </p>
                  </div>

                  <div class="b-gallery">
                    <div class="col-6 col-lg-4 col-image" v-for="(g, gInx) in gallery" :key="'gInx-'+gInx">
                      <img class="t-150 placed-backg" src="public/images/empty.png" v-bind:style="{ backgroundImage: 'url('+g.src+')' }" @click="setIndex(gInx)">
                    </div>
                  </div>
                </div>
              </div>

              <div class="box-related">
                <div class="row box-top">
                  <div class="col-md-8 col-title">
                    <h2 class="title-s2">Related tours</h2>
                  </div>

                  <div class="col-md-4 col-buttons">
                    <router-link class="t-150" to="/tours">View all tours</router-link>
                  </div>
                </div>

                <div class="box-tours">
                  <swiper class="swiper" :options="relatedOptions">
                    <swiper-slide v-for="(tour, thInx) in tours" :key="'thInx-'+thInx">
                      <div class="col-12 px-0 col-tour-s1">
                        <router-link class="box-link" to="/tour/1">
                          <div class="box-image">
                            <img :src="tour.imageUrl">
                          </div>

                          <div class="box-info">
                            <h6 class="days"><i class="fa-light fa-calendar icon"></i> 8 Days</h6>

                            <h4 class="name">{{ tour.name }}</h4>

                            <div class="b-stars">
                              <b-form-rating inline value="4" readonly></b-form-rating>
                            </div>

                            <div class="b-price">
                              <h5 class="price">{{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(tour.price) }} $</h5>
                              <h5 class="price old-price">{{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(tour.oldPrice) }} $</h5>
                            </div>

                            <div class="b-description">
                              <p>
                                Nam exercitationem commodi et ducimus quia in dolore animi sit mollitia amet id quod eligendi. Et labore harum
                                non nobis ipsum eum molestias mollitia et corporis
                                praesentium a laudantium internos.
                              </p>
                            </div>

                            <a class="btn-explore">Explore Now</a>
                          </div>
                        </router-link>
                      </div>
                    </swiper-slide>

                    <div class="swiper-pagination" slot="pagination"></div>
                  </swiper>
                </div>
              </div>

              <div class="box-products">
                <div class="row box-top">
                  <div class="col-md-8 col-title">
                    <h2 class="title-s2">Complementary products</h2>
                  </div>

                  <div class="col-md-4 col-buttons">
                    <router-link class="t-150" to="/products">View all products</router-link>
                  </div>
                </div>

                <div class="b-products">
                  <swiper class="swiper" :options="relatedOptions">
                    <swiper-slide v-for="(a, pInx) in products" :key="'pInx-'+pInx">
                      <div class="col-12 px-0 col-product-s1">
                        <router-link class="box-link" to="/products/1">
                          <div class="box-image">
                            <img class="placed-backg" src="public/images/pages/products/product.png" v-bind:style="{ backgroundImage: 'url('+a.imageUrl+')' }">
                          </div>
                          <div class="box-info">
                            <h5 class="name">{{ a.name }}</h5>
                            <h5 class="price">$350.00</h5>
                          </div>

                          <div class="box-buttons">
                            <a class="btn-shop">Shop Now</a>
                          </div>
                        </router-link>
                      </div>
                    </swiper-slide>

                    <div class="swiper-pagination" slot="pagination"></div>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <CoolLightBox
      :items="gallery"
      :index="index"
      :effect="'fade'"
      loop
      @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>
// https://vuejsexamples.com/vue-js-lightbox-package-without-any-dependencies/
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      faqs: [
        { q: 'Lorem ipsum dolor sit amet consectetur. Lorem eget diam a justo. Tellus nunc scelerisque ut ut sollicitudin integer porta amet.' },
        { q: 'Lorem ipsum dolor sit amet consectetur. Lorem eget diam a justo. Tellus nunc scelerisque ut ut sollicitudin integer porta amet.' },
        { q: 'Lorem ipsum dolor sit amet consectetur. Lorem eget diam a justo. Tellus nunc scelerisque ut ut sollicitudin integer porta amet.' },
        { q: 'Lorem ipsum dolor sit amet consectetur. Lorem eget diam a justo. Tellus nunc scelerisque ut ut sollicitudin integer porta amet.' },
      ],

      index: null,
      gallery: [
        { src: 'public/images/pages/tours/g-image-1.jpg' },
        { src: 'public/images/pages/tours/g-image-2.jpg' },
        { src: 'public/images/pages/tours/g-image-3.jpg' },
        { src: 'public/images/pages/tours/g-image-4.jpg' },
        { src: 'public/images/pages/tours/g-image-5.jpg' },
        { src: 'public/images/pages/tours/g-image-6.jpg' },
      ],

      tours: [
        { imageUrl: 'public/images/pages/tours/tour-1.jpg', name: 'Cancun', price: 1000, oldPrice: 1200 },
        { imageUrl: 'public/images/pages/tours/tour-2.jpg', name: 'Chichen Itza', price: 1223, oldPrice: 1400 },
        { imageUrl: 'public/images/pages/tours/tour-3.jpg', name: 'Tulum', price: 1200, oldPrice: 1300 },
        { imageUrl: 'public/images/pages/tours/tour-4.jpg', name: 'Playa de Carmen', price: 1100, oldPrice: 1200 },
        { imageUrl: 'public/images/pages/tours/tour-2.jpg', name: 'Chichen Itza', price: 1223, oldPrice: 1400 },
      ],

      blog: [
        { imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Your dream vacation awaits: Top tours in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Must-see spots in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'The ultimate guide to touring Playa del Carmen' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Discover the magic of Mexico’s Rivera Maya' },
      ],

      products: [
        { imageUrl: 'public/images/pages/products/product-1.jpg', name: 'Set snorkelling with snorket and diving googles' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Sprinter diving fins' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', name: 'Waterproof camera shockproof for swimming underwater 1050 HD' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', name: 'Diving Dive Lights AL-18LED' },
      ],

      // == Carousels options ==
      bannersOptions: {
        loop: true,
        effect: 'fade',
        speed: 700,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      },
      relatedOptions: {
        slidesPerView: 3,
        spaceBetween: 10,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: false
        },

        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: false
            }
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
        }
      },
      // == ==
    }
  },

  methods: {
    setIndex(index) {
      this.index = index
    }
  },
}
</script>
