<template lang="html">
  <footer id="footer">
    <div class="container oversized-container">
      <div class="row">
        <div class="col-12 col-lg-4 col-footer col-c1">
          <p class="mb-4">
            <router-link class="logo" to="/">
              <img src="public/images/logo.png">
            </router-link>
          </p>

          <h5 class="title">LISTEN TO OUR PODCAST</h5>
					<p>
						<a class="t-150 btn-podcast" target="_blank" href="https://open.spotify.com/show/4oIlQNUufeT3X6vgAGWq6N">
							<img src="public/images/icon-spotify.svg">
						</a>

						<a class="t-150 btn-podcast" target="_blank" href="https://soundcloud.com/traveltips">
							<img src="public/images/icon-soundcloud.svg">
						</a>

						<a class="t-150 btn-podcast" target="_blank" href="https://itunes.apple.com/mx/podcast/kay-tours-mexico/id1323554456?l=en">
							<img src="public/images/icon-apple.svg">
						</a>

						<a class="t-150 btn-podcast" target="_blank" href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8xYjIyZjJjL3BvZGNhc3QvcnNz">
							<img src="public/images/icon-google-podcast.svg">
						</a>
					</p>
        </div>

        <div class="col-sm-6 col-lg-4 col-footer">
          <h5 class="title">MORE INFO<br />COMMUNITY</h5>

					<p class="mb-2 f-w-500">
						<router-link to="">Terms & Conditions</router-link>
					</p>
					<p class="mb-2 f-w-500">
						<router-link to="">Privacy Politics</router-link>
					</p>
					<p class="mb-2 f-w-500">
						<router-link to="">Medical Form</router-link>
					</p>
					<p class="f-w-500">
						<router-link to="">Blog</router-link>
					</p>
        </div>

        <div class="col-sm-6 col-lg-4 col-footer">
          <h5 class="title"><span class="d-none d-sm-inline-block"><br /></span>SOCIAL</h5>

					<p class="mb-3 mb-sm-4">
						<a class="btn-network" target="_blank" href="https://web.facebook.com/KayToursMexico/">
							<i class="fa-brands fa-facebook-f"></i>
						</a>
						<a class="btn-network" target="_blank" href="https://twitter.com/kaytoursmexico">
							<i class="fa-brands fa-x-twitter"></i>
						</a>
						<a class="btn-network" target="_blank" href="https://goo.gl/JD2QT0">
							<i class="fa-brands fa-youtube"></i>
						</a>
						<a class="btn-network" target="_blank" href="http://www.pinterest.com/kaytoursmexico">
							<i class="fa-brands fa-pinterest"></i>
						</a>
						<a class="btn-network" target="_blank" href="https://www.instagram.com/kaytoursmexico">
							<i class="fa-brands fa-instagram"></i>
						</a>
						<a class="btn-network" target="_blank" href="mailto:contact@kay.tours?subject=I'd like to know more about">
							<i class="fa-light fa-envelope"></i>
						</a>
					</p>

					<p>
						<b-dropdown class="dropdown-langs">
					    <template #button-content>
					      <span><img class="flag" src="public/images/flag-usa.jpg"> ENGLISH</span>
					    </template>
					    <b-dropdown-item><img class="flag" src="public/images/flag-usa.jpg"> ENGLISH</b-dropdown-item>
					    <b-dropdown-item><img class="flag" src="public/images/flag-german.jpg"> GERMAN</b-dropdown-item>
					  </b-dropdown>
					</p>
        </div>

				<div class="col-12 col-copy">
					<hr />
					<h6>© Kay Tours México {{ currentYear }}. All Rights Reserved.</h6>
				</div>
			</div>
    </div>
  </footer>
</template>

<script>
export default {
	data() {
		return {
			currentYear: 0,
		}
	},

	beforeMount() {
		this.currentYear = new Date().getFullYear();
	}
}
</script>
