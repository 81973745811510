<template lang="html">
  <div id="about-us-page">

    <section class="main-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-text">
            <h2 class="title-s2">About Kay Tours Mexico</h2>

            <p class="txt-from">
              Kay [\ˈkī\\]: - from a Greek word meaning "rejoice”<br />
              Mayan language translates it as "fish"
            </p>

            <p class="mb-3">
              "We want to make a difference and we mean it! - That is another reason why I put my name on it!"
            </p>
            <p class="mb-3">
              After a career as international purchaser at a Dutch Flower Import- Export Company Kay decided to move to Mexico in 2012. ​ By doing a friend a favor and helping him out as a translator and eventually as a tour guide, Kay started his guiding career at the biosphere reserve of Sian Ka'an. ​
            </p>
            <p>
              He changed to another tour operator after a year and started guiding whale shark tours. Sian Ka'an and the Whale Shark Encounter are still his favorite excursions and one of the many reasons for his passion for Mexico. ​
            </p>
          </div>

          <div class="col-lg-4 col-image">
            <div class="photo">
              <img class="placed-backg" src="public/images/empty.png" v-bind:style="{ backgroundImage: 'url(public/images/pages/about/photo.jpg)' }">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="extra-info-section">
      <div class="container">
        <p class="mb-3">
          Kay's company grew out of the realization that many local tour operators were not sufficiently satisfying their customer's needs; thus Kay devoted himself to providing greater care and attention in crafting memorable and personalized tours.
        </p>
        <p class="mb-3">
          Kay’s experience working for a number of local tour operators, along with his passion for excellence, nature and making others happy, has ensured that his company is now one of the region’s leading operators. ​ The guides at Kay Tours Mexico are skillful, versatile and knowledgeable.
        </p>
        <p>
          Each guide is at least bilingual and has many years of experience working in the Riviera Maya area, guaranteeing visitors receive the highest quality of service both during the planning stages of their day tour and during the actual excursion.
        </p>

        <div class="box-video">
          
        </div>

        <h4 class="mt-4 mb-2 txt-2">Our guiding principle is to make your day with us</h4>
        <h3 class="txt-2">The Best Day of Your Vacation!</h3>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
