<template lang="html">
  <div id="article-page">

    <section class="container article-section">
      <div class="row">
        <div class="col-12 col-breadcrumb">
          <router-link to="/blog">Blog</router-link>
          <span>Your dream vacation awaits: Top tours in Cancun</span>
        </div>

        <div class="col-12 col-article">
          <div class="image">
            <img src="public/images/pages/blog/article-1.jpg">
          </div>

          <h1 class="title">Your dream vacation awaits: Top tours in Cancun</h1>
          <h5 class="author">By Adam Smith</h5>
          <div class="box-content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <br />
            <p>
              Lorem ipsum dolor <a>sit amet</a>, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <br />
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
            </p>
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetur adipisicing.</li>
              <li>Eiusmod tempor incididunt ut labore.</li>
              <li>Sed do eiusmod tempor incididunt.</li>
              <li>Ipsum dolor sit amet, consectetur adipisicing.</li>
            </ul>

            <br />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="container related-section">
      <div class="d-block text-center mb-3">
        <h3 class="title-s2">Related articles</h3>
      </div>

      <swiper class="swiper" :options="blogOptions">
        <swiper-slide v-for="(a, ahInx) in blog" :key="'ahInx-'+ahInx">
          <div class="col-12 px-0 col-blog-s1">
            <div class="box-link">
              <div class="box-image">
                <img :src="a.imageUrl">
              </div>

              <div class="box-info">
                <h4 class="title">{{ a.title }}</h4>

                <div class="b-description">
                  <p>
                    Lorem ipsum dolor sit amet, con turadip iscingelit. In sed et donec purus viverra. Sit justo velit, eu sed.
                  </p>
                </div>

                <h6 class="author"><span>By</span> Adam Smith</h6>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      blog: [
        { imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Must-see spots in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'The ultimate guide to touring Playa del Carmen' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Discover the magic of Mexico’s Rivera Maya' },
        { imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Your dream vacation awaits: Top tours in Cancun' },
      ],

      // == Blog ==
      blogOptions: {
        slidesPerView: 3,
        spaceBetween: 10,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: false
        },

        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
        }
      },
      // == ==
    }
  }
}
</script>
