<template lang="html">
  <header id="header">
    <!-- Header menu -->
    <div class="header-menu " v-bind:class="{ 'transparent-header' : ($route.name == 'homePage' || $route.name == 'tourDescrPage') }">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img class="logo" src="public/images/logo.png">
            <img class="logo-white" src="public/images/logo-white.png">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <i class="fa-sharp fa-regular fa-bars"></i>
          </b-navbar-toggle>

          <b-collapse class="nav-collapsible" id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="nav-w-dropdown" @click="openToursModal()">Tours</b-nav-item>
              <b-nav-item class="nav-w-dropdown" to="/services">Services</b-nav-item>
              <!-- <b-nav-item class="nav-w-dropdown" to="">Products</b-nav-item> -->
              <b-nav-item class="d-lg-none d-xl-inline-block" to="/reviews">Reviews</b-nav-item>
              <b-nav-item to="/faq">FAQ</b-nav-item>
              <b-nav-item-dropdown right>
                <template #button-content>
                  <span>Eng</span>
                </template>
                <b-dropdown-item><img class="flag" src="public/images/flag-usa.jpg"> ENGLISH</b-dropdown-item>
                <b-dropdown-item><img class="flag" src="public/images/flag-german.jpg"> GERMAN</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item to="/contact"><span class="d-lg-none">Contact</span> <i class="fa-light fa-envelope"></i></b-nav-item>
              <b-nav-item to="/cart"><span class="d-lg-none">Cart</span> <i class="fa-light fa-cart-shopping"></i></b-nav-item>
              <b-nav-item-dropdown right>
                <template #button-content>
                  <span><span class="d-lg-none">My account</span> <i class="fa-regular fa-user"></i></span>
                </template>
                <b-dropdown-item to="/login">Login</b-dropdown-item>
                <b-dropdown-item to="/register">Register</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
    <!--  -->

    <!-- Modal | Tours -->
    <b-modal modal-class="modal-tours" ref="modal-tours" scrollable  hide-footer centered>
      <div class="box-top">
        <a @click="linkTo('/tours')">View all tours</a>
      </div>

      <div class="row">
        <a class="col-12 col-tour" v-for="(t, thInx) in tours" :key="'thInx-'+thInx" @click="linkTo('/tour/1')">
          <div class="row">
            <div class="col col-image">
              <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+t.imageUrl+')' }"></div>
            </div>

            <div class="col col-info">
              <h5 class="name">{{ t.name }}</h5>

              <div class="b-description">
                <p>
                  Lorem impsu dolor sit amet consectetur.<br />
                  Scelerisque eu masso odio consequat sem senectus montes ac id.
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </b-modal>
    <!--  -->
  </header>
</template>

<script>
export default {
  data() {
    return {
      tours: [
        { imageUrl: 'public/images/pages/tours/tour-1.jpg', name: 'Cancun' },
        { imageUrl: 'public/images/pages/tours/tour-2.jpg', name: 'Chichen Itza' },
        { imageUrl: 'public/images/pages/tours/tour-3.jpg', name: 'Tulum' },
        { imageUrl: 'public/images/pages/tours/tour-4.jpg', name: 'Playa de Carmen' },
        { imageUrl: 'public/images/pages/tours/tour-2.jpg', name: 'Chichen Itza' },
      ]
    }
  },

  methods: {
    openToursModal() {
      this.$refs['modal-tours'].show();
    },

    linkTo(url) {
      this.$refs['modal-tours'].hide();
      this.$router.push({ path: url })
    },
  },

  mounted() {}
}
</script>
