<template lang="html">
  <div id="home-page">

    <section class="banners-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners" :key="'bhInx-'+bhInx">
          <router-link to="/tours">
            <img :src="b">
          </router-link>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(bm, bhInx) in bannersMovil" :key="'bhInx-'+bhInx">
          <router-link to="/tours">
            <img :src="bm">
          </router-link>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="benefits-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-4 col-comments">
            <div class="box-comments">
              <div class="inside">
                <swiper class="swiper" :options="commentsOptions">
                  <swiper-slide>
                    <div class="box-comment">
                      <div class="b-stars">
                        <b-form-rating inline value="4" readonly></b-form-rating>
                      </div>

                      <div class="b-title">
                        <h6>❝Best vacations ever❞</h6>
                      </div>

                      <div class="b-description">
                        <div class="b">
                          <p>
                            Et eligendi expedita. Accusantium qui est elus nemo eaque dolore necessitatibus voluptatem.<br />
                            Ut accusamus provident beatae dolorum amet. Omnis sapiente aut saepe aliquam doloribus eaque. lure sit sed animi molestiae impedit.
                          </p>
                        </div>
                      </div>

                      <div class="b-user">
                        <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/photo.jpg)' }"></div>
                        <div class="text">
                          <h6>From: Sophia Jenkins</h6>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>

                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 col-list">
            <div class="box-content">
              <div class="b-title bg-1">
                <h5 class="title">Benefits</h5>
              </div>

              <div class="b-content">
                <div class="inside">
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> Pick up at your hotel</p>
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> Free cancelations</p>
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> Full flexibility</p>
                  <p><i class="fa-solid fa-check icon"></i> Reserve now, Pay later</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 col-list">
            <div class="box-content">
              <div class="b-title bg-2">
                <h5 class="title">Why Choose Us?</h5>
              </div>

              <div class="b-content">
                <div class="inside">
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> Exclusive transportation</p>
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> No risk</p>
                  <p><i class="fa-solid fa-check icon"></i> Reserve now, pay later</p>

                  <p class="pl-3 mt-2 mt-lg-3">
                    <router-link class="ml-3 ml-xl-4 link-more t-150" to=""><u>See More</u></router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="tours-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-md-8 col-title">
            <h2 class="title-s1">Our trending tour<br />packages</h2>
          </div>

          <div class="col-md-4 col-buttons">
            <router-link class="t-150" to="/tours">View all tours</router-link>
          </div>
        </div>

        <div class="row row-tours">
          <div class="col-12">
            <swiper class="swiper" :options="toursOptions">
              <swiper-slide v-for="(tour, thInx) in tours" :key="'thInx-'+thInx">
                <div class="col-12 px-0 col-tour-s1">
                  <router-link class="box-link" to="/tour/1">
                    <div class="box-image">
                      <img :src="tour.imageUrl">
                    </div>

                    <div class="box-info">
                      <h6 class="days"><i class="fa-light fa-calendar icon"></i> 8 Days</h6>

                      <h4 class="name">{{ tour.name }}</h4>

                      <div class="b-stars">
                        <b-form-rating inline value="4" readonly></b-form-rating>
                      </div>

                      <div class="b-price">
                        <h5 class="price">{{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(tour.price) }} $</h5>
                        <h5 class="price old-price">{{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(tour.oldPrice) }} $</h5>
                      </div>

                      <div class="b-description">
                        <p>
                          Nam exercitationem commodi et ducimus quia in dolore animi sit mollitia amet id quod eligendi. Et labore harum
                          non nobis ipsum eum molestias mollitia et corporis
                          praesentium a laudantium internos.
                        </p>
                      </div>

                      <a class="btn-explore">Explore Now</a>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="faq-section-st-1">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-7 col-content">
            <h2 class="title-s1">F.A.Q.</h2>

            <div class="accordion" role="tablist">
              <b-card no-body v-for="(qa, qaInx) in faq" :key="'qaInx-'+qaInx">
                <b-card-header header-tag="header" role="tab">
                  <a block v-b-toggle="'accordion-'+qaInx">{{ qa.q }}</a>
                </b-card-header>
                <b-collapse :visible="(qaInx === 0) ? true : false"  :id="'accordion-'+qaInx" accordion="faqs-accordion" role="tabpanel">
                  <b-card-body>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-lg-5 col-image">
            <img src="public/images/pages/home/faq-image.jpg">
          </div>
        </div>
      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-title">
            <h5 class="subtitle">BLOG</h5>
            <h2 class="title-s1">Where will you go next?</h2>
          </div>
        </div>

        <div class="row row-carousel">
          <div class="col-12">
            <swiper class="swiper" :options="blogOptions">
              <swiper-slide v-for="(a, ahInx) in blog" :key="'ahInx-'+ahInx">
                <div class="col-12 px-0 col-blog-s1">
                  <router-link class="box-link" to="/blog/1">
                    <div class="box-image">
                      <img :src="a.imageUrl">
                    </div>

                    <div class="box-info">
                      <h4 class="title">{{ a.title }}</h4>

                      <div class="b-description">
                        <p>
                          Lorem ipsum dolor sit amet, con turadip iscingelit. In sed et donec purus viverra. Sit justo velit, eu sed.
                        </p>
                      </div>

                      <h6 class="author"><span>By</span> Adam Smith</h6>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <ReviewsComponent></ReviewsComponent>

  </div>
</template>

<script>
import ReviewsComponent from './reviews/reviews-component.vue'
export default {
  components: {
    ReviewsComponent
  },

  data() {
    return {
      banners: [
        'public/images/pages/home/banner-1.jpg',
        'public/images/pages/home/banner-2.jpg',
        'public/images/pages/home/banner-3.jpg',
      ],
      bannersMovil: [
        'public/images/pages/home/banner-1-m.jpg',
        'public/images/pages/home/banner-2-m.jpg',
        'public/images/pages/home/banner-3-m.jpg',
      ],

      tours: [
        { imageUrl: 'public/images/pages/tours/tour-1.jpg', name: 'Cancun', price: 1000, oldPrice: 1200 },
        { imageUrl: 'public/images/pages/tours/tour-2.jpg', name: 'Chichen Itza', price: 1223, oldPrice: 1400 },
        { imageUrl: 'public/images/pages/tours/tour-3.jpg', name: 'Tulum', price: 1200, oldPrice: 1300 },
        { imageUrl: 'public/images/pages/tours/tour-4.jpg', name: 'Playa de Carmen', price: 1100, oldPrice: 1200 },
        { imageUrl: 'public/images/pages/tours/tour-2.jpg', name: 'Chichen Itza', price: 1223, oldPrice: 1400 },
      ],

      faq: [
        {
          q: 'Is Chichen Itza open despite COVID-19?',
        },
        {
          q: 'What are the best tours in Playa del Carmen?',
        },
        {
          q: 'Do you need to book Chichen Itza tickets in advance?',
        },
        {
          q: 'What is the best way to see Chichen Itza?',
        },
      ],

      blog: [
        { imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Your dream vacation awaits: Top tours in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Must-see spots in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'The ultimate guide to touring Playa del Carmen' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Discover the magic of Mexico’s Rivera Maya' },
      ],

      // == Carousels ==
      bannersOptions: {
        effect: 'fade',
        speed: 700,
        loop: true,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      },
      commentsOptions: {
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      toursOptions: {
        slidesPerView: 4,
        spaceBetween: 10,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: false
        },

        breakpoints: {
          1500: {
            slidesPerView: 4,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: false
            }
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: false
            }
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
        }
      },
      blogOptions: {
        slidesPerView: 4,
        spaceBetween: 10,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: false
        },

        breakpoints: {
          1400: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          1200: {
            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: false
            }
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
        }
      },
      // == ==
    }
  },

  methods: {
  }
}
</script>
