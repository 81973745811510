<template lang="html">
  <div id="faqs-page">

    <section class="faq-section-st-1">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-7 col-content">
            <h2 class="title-s1">F.A.Q.</h2>

            <div class="accordion" role="tablist">
              <b-card no-body v-for="(qa, qaInx) in faq" :key="'qaInx-'+qaInx">
                <b-card-header header-tag="header" role="tab">
                  <a block v-b-toggle="'accordion-'+qaInx">{{ qa.q }}</a>
                </b-card-header>
                <b-collapse visible :id="'accordion-'+qaInx" :accordion="'faqs-accordion-'+qaInx" role="tabpanel">
                  <b-card-body>
                    <div v-html="qa.a"></div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-lg-5 col-image">
            <img src="public/images/pages/home/faq-image.jpg">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      faq: [
        {
          q: 'What time we will be picked up at our hotel?',
          a: `
            <p>
              All stated pickup times are recommendations. We like to be the first at the sites, so our guests can have an unique experience with only a few, in the best case no other visitors.<br />
              <br />
              Depending on your hotels/vacation rentals location and your first destination the recommendated pickup time is between 05:30 a.m. and 08:00 a.m.<br />
              <br />
              Let us send you a free sample itinerary and we will let you know what time we actually recommend to start your exclusive private tour. If you want us to pick you up at a different time, just let us know and we will be happy to adjust our itinerary to your needs.
            </p>
          `
        },
        {
          q: 'What else should I bring and wear on the tour?',
          a: `
            <p>
              We recommend to bring the following items:
            </p>
            <ul class="pb-1">
              <li>Towel (your hotel will provide these) - should you stay at an Airbnb these Microfiber towels are awesome</li>
              <li>Sandals or flip-flops for cenotes and boat trips</li>
              <li>Comfortable walking / sneakers for archaeological sites</li>
              <li>Sunglasses and a hat</li>
              <li>Change of clothes</li>
              <li>Biodegradable sunscreen and/or repellent</li>
              <li>Some money for, souvenirs and tips</li>
            </ul>
            <p style="text-align:center">
              <img class="img-shad" src="public/images/pages/faqs/img-1.jpg" />
            </p>
          `
        },
        {
          q: 'Which Sunscreen can I use?',
          a: `
            <p>
              We highly recommend the use of rashguards rather than sunscreen. Sunscreen is a threat to our environment because of its ingredients. Parks like Xcaret, Xel-ha only allow "biodegradable" lotions, but be careful just because a product states it is biodegradable, it does not mean it is good for the coral reef.<br />
              <br />
              The main components of most sunscreens are zinc oxide and titanium dioxide—ingredients that will never biodegrade and have the potential to harm corals and sea life. Some also contain mineral oil (petroleum) which has a low solubility rate in water, is slow to biodegrade and is known to be harmful or fatal to some aquatic life and birds. <br />
              <br />
              When considering buying sunscreen, please listen to our podcast "<a class="f-w-600" target="_blank" href="https://open.spotify.com/episode/6U278eAwgS8McAkKS70L5k">Sunscreen and Coral Reefs</a>" It will help you to understand the importance of the right sunscreen.<br />
              <br />
              If you are in a hurry, we have you covered. We took a closer look at the best-rated sunscreens marketed specifically for use on babies and kids.<br />
              <br />
              The highest rated sunscreen for babies is <a target="_blank" class="f-w-600" href="https://www.amazon.com/gp/product/B07NJPR4TN/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07NJPR4TN&linkCode=as2&tag=kay.tours-riviera-maya-blog-20&linkId=248ca36288443f9df974c83077787df1">Babo Botanicals</a> Baby Skin Mineral Sunscreen and for children: <a class="f-w-600" target="_blank" href="https://www.amazon.com/gp/product/B07NBXZ8GP/ref=as_li_tl?ie=UTF8&tag=kay.tours-riviera-maya-blog-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B07NBXZ8GP&linkId=24de89073c6f7e4743dc3d0421de7e65">Sunology Mineral Sunscreen</a> Lotion, Kids, SPF 50<br />
              <br />
              For adults we recommend a combination of a rashguard and <a target="_blank" class="f-w-600" href="https://www.amazon.com/gp/product/B07CVRDGQV/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07CVRDGQV&linkCode=as2&tag=kay.tours-riviera-maya-blog-20&linkId=3848dee4c0dc5b0df12da18da0bf3f2f">Raw Love</a> All Natural Mineral Sunscreen SPF 35 Safe for Reefs<br />
              <br />
              Please do NOT put sunscreen on directly before you go into the water.
            </p>
            <br />
            <h6>Our own brand of Rash Guards</h6>
            <p>
              Our experiences as tour guides in extreme weather situations and long time exposure to the sun has directed the development of these shirts in any aspect.
            </p>
            <br />
            <p class="text-center">
              <img class="img-shad" style="max-height: 220px;" src="public/images/pages/faqs/img-2.jpg" />
            </p>
            <br />
            <p>
              We've designed our shirts for all kind of outdoor activities, which makes them suitable for a day of fishing, sailing, swimming, snorkeling, scuba diving, hiking, traveling, boating, climbing, running, workout and any other of your favorite sports.<br />
              <br />
              UV Protection with a 50+ UPF rating provides excellent protection against ultraviolet rays and helps you to stay safe in the sun.
            </p>
            <br />
            <p>
              <a class="f-w-600">Visit our shop</a>
            </p>
          `
        },
        {
          q: 'What if I never snorkeled before or had a bad experience?',
          a: `
            <p>
              It will be a pleasure for us to introduce you to this amazing activity. Your personal tour guide will take time to explain to you the technique of snorkeling and will practice with you until you feel comfortable. We will never rush you into the water or put pressure on you. Take this as an opportunity to learn about a new activity which easily can become a passion.
            </p>
          `
        },
        {
          q: 'Do I have to snorkel?',
          a: `
            <p>
              Some people just don't want to snorkel and there is no problem at all with that. There are always places with shade where you can relax while the rest of the group is in the water.
            </p>
          `
        },
        {
          q: 'Do I have to bring my own snorkel equipment?',
          a: `
            <p>
              Feel free to bring your own equipment, but it is not necessary since we provide you with clean and professional snorkel equipment.
            </p>
          `
        },
        {
          q: 'What if it is raining the morning of the tour?',
          a: `
            <p>
              Please be aware, the weather here is very local and if it is raining at your hotel chances are really high the sun is shining where we are heading to. Do not assume a tour service is canceled by rain, only the operator can determine if a tour should be canceled or rescheduled due to the weather conditions. In that case, we will contact you during the afternoon before your tour.<br />
              <br />
              For more information read our blog post about the <a class="f-w-600" target="_blank" href="https://www.kay.tours/post/how-is-the-weather-at-your-caribbean-travel-destination-does-it-matter">Caribbean Weather</a>.<br />
              <br />
              Don't forget, you are in Paradise! The only limitation that might occur is a closed beach or port, because of safety reasons.<br />
              <br />
              If your tour is cancelled by the tour operator for any reason including weather condition you’ll receive a full refund or be able to reschedule your tour with no penalty.
            </p>
          `
        },
        {
          q: 'What is the pricing of a tour?',
          a: `
            <p>
              The price depends on the number of guests and how many locations/activities you are planning to see. <strong class="f-w-600">Our rates decrease as the guest count rises! Once you are at our booking page fill in your group size at each individual tour to know your actual price.</strong><br />
              <br />
              Do not hesitate to send us a request and we will send you a free, non-binding and personalized itinerary with prices.
            </p>
          `
        },
        {
          q: 'What does the price include?',
          a: `
            <p>
              Your excursion includes a private guide for an exclusive experience, round-trip transportation in a clean, air-conditioned, and fully insured vehicle, ensuring safe travel. Also included are fuel costs, parking fees, entrance fees, a licensed driver, along with snacks and beverages. While all key expenses are covered, bringing extra cash for souvenirs and tips is advisable.
            </p>
          `
        },
        {
          q: 'How can I make a reservation?',
          a: `
            <p>
              If you would like to go ahead with your booking please pay the deposit due. Use the <strong class="f-w-600">"VIEW PRICES & AVAILABILITY"</strong> button to go to the check-out process next to each tour itinerary.
            </p>
          `
        },
        {
          q: 'What is the cancellation policy?',
          a: `
            <h5>Cancellation policy</h5>
            <br />
            <ol>
              <li>
                <strong class="f-w-600">Cancellation Process:</strong><br />
                <p>
                  All cancellations must be made via email and acknowledged by your sales representative or directly with Kay Tours Mexico to be considered valid.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">No-Show Policy:</strong><br />
                <p>
                  No-shows will result in the full price being charged.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">Rescheduling:</strong><br />
                <p>
                  Rescheduling may incur fees depending on the notice provided and the excursion booked.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">Refund and Credit Policy:</strong><br />
                <p>
                  Full refund or credit will be provided in case of cancellation due to weather or unforeseen circumstances.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">Day-of Service Changes:</strong><br />
                <p>
                  Changes made to the itinerary or headcount on the day of the service will not result in any price adjustments.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">Cancellation Notice:</strong><br />
                <p>
                  If you need to cancel your Dream Day, we are happy to assist. A full refund or credit will be provided with 24 hours notice prior to the agreed pickup time of the confirmed service, and no cancellation fees will apply.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">Cancellation Fees:</strong><br />
                <p>
                  For cancellations made within less than 24 hours prior to the agreed pickup time, a cancellation fee of 30% will be applicable. However, this does not apply to catamarans or yachts. Please see specific cancellation fees below.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">Large Group Policy:</strong><br />
                <p>
                  For groups of 10 or more guests, the notification time for cancellation is 48 hours prior to the agreed pickup time of the confirmed service. This is due to higher costs involved and a higher percentage of guests not showing up than average.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">No-Show Charges:</strong><br />
                <p>
                  No-shows will result in the full price being charged.
                </p>
                <br />
              </li>
              <li>
                <strong class="f-w-600">CANCELLATION FEES FOR YACHT, FISHING AND CATAMARAN CHARTERS</strong><br />
                <p>
                  The only weather factor that leads to a cancellation and no penalties is if the port authority deems recreational maritime activities to be unsafe. Rain alone does not undo planning on our part.
                </p>
                <p>
                  <span class="circle-cancellation" style="background: #11c024"></span> 7 days before the confirmed service, no penalty, we will refund all payments done.
                </p>
                <p>
                  <span class="circle-cancellation" style="background: #dab112"></span> 6 to 4 days before pick up 30% refund of the total amount will be charged.
                </p>
                <p>
                  <span class="circle-cancellation" style="background: #f16a07"></span> 48 to 72 hours before pick up 50% of the total amount will be charged.
                </p>
                <p>
                  <span class="circle-cancellation" style="background: #c00505"></span> Less than 48 hours before pick up 100% of the amount will be charged.
                </p>
              </li>
            </ol>
          `
        },
        {
          q: 'Is it safe to pay on your website?',
          a: `
            <p>
              Yes it is! Bookings and payments with us are secured with 2048-bit encryption and your privacy is protected. Kay Tours Mexico is committed to protecting your privacy. Kay Tours Mexico does not sell, rent or lease its clients lists to third parties.
            </p>
          `
        },
        {
          q: 'Why do we need your room number and a phone number?',
          a: `
            <p>
              We need your room number stated in our service order, as a proof that you contracted us and in order to be able to pick you up at your hotel's lobby.<br />
              <br />
              Your phone number is needed to get a hold of you in case we have to re-schedule, cancel or let you know about any changes. It makes our communication easier if we can call you, or chat with you.<br />
              <br />
              Feel free to add us and to chat with us whenever you have a question. We are happy to help!<br />
              <br />
              Should you have any questions, please do not hesitate to contact us.​
            </p>
            <br />
            <p>
              <strong class="f-w-600">CALL US, OR LET US CALL YOU!</strong><br />
              Outside MEX: +5219841432347<br />
              Call Inside MEX: 9841432347<br />
              Whatsapp: <a target="_blank" href="https://wa.me/5219841432347">+5219841432347</a><br />
              Email: <a href="mailto:contact@kay.tours">contact@kay.tours</a>
            </p>
          `
        },
        {
          q: 'What if I have allergies or special needs?',
          a: `
            <p>
              We, as your private tour guides are responsible for you. Please let us know in advance in case you have any condition, history of heart diseases, asthma, allergies, pregnancy, etc.<br />
              <br />
              Please send us the completed Medical Form, so we know what to do in case something happens. This is very helpful information for us in order to ensure you will have a great and safe day.<br />
              <br />
              Should you be allergic or not eat certain type of food or ingredients, please inform us in your reservation, so we can take care of this and find alternatives for you.
            </p>
          `
        },
        {
          q: 'What is the weight limit of the zip-lines?',
          a: `
            <p>
              The weight limit is 275 lbs or 125 kg.
            </p>
          `
        },
        {
          q: 'How can I contact Kay Tours Mexico?',
          a: `
            <p>
              In the best case you write us an email: contact@kay.tours <br />
              <br />
              We are checking our emails constantly, but should you not receive an answer within 12 hours please re-send your mail.<br />
              <br />
              Outside MEX: +52 984 207 4374<br />
              Within MEX: 984 207 4374<br />
              <a target="_blank" href="https://chat.whatsapp.com/HDTGsq1WZIm1khC5BlwgIq"><i class="fa-brands fa-whatsapp"></i> WhatsApp</a> or <a target="_blank" href="https://tawk.to/chat/615b4b31d326717cb684b2fa/1fh69rebk"><i class="fa-regular fa-comment"></i> Chat</a>
            </p>
          `
        },
      ],
    }
  }
}
</script>
