<template lang="html">
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150.26">
    <path class="icon" d="m133.47.02S16.77-.02,16.8.02C6.05.02,0,5.58,0,16.94v117.13c0,10.63,5.44,16.19,16.18,16.19h117.63c10.75,0,16.19-5.29,16.19-16.19V16.94C150,5.85,144.56.02,133.47.02Zm-5.35,33.69l-28.24,28.29,16.04,62.08-10.56,10.56-26.91-51.89-21.12,21.12,1.92,16.33-8.35,8.36-9.8-18.63-18.63-9.79,8.36-8.36,16.42,2.02,21.12-21.12-52.23-27.5,10.56-10.56,63.36,16.75,27.66-27.66c7.49-7.49,17.98,2.43,10.4,10.01Z"/>
  </svg>
</template>

<script>
export default {
}
</script>
