<template lang="html">
  <div id="tours-products-page">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-9 col-results">
            <div class="row">
              <div class="col-md-6 col-product-s1" v-for="(a, pInx) in products" :key="'pInx-'+pInx">
                <router-link class="box-link" to="/products/1">
                  <div class="box-image">
                    <img class="placed-backg" src="public/images/pages/products/product.png" v-bind:style="{ backgroundImage: 'url('+a.imageUrl+')' }">
                  </div>
                  <div class="box-info">
                    <h5 class="name">{{ a.name }}</h5>
                    <h5 class="price">$350.00</h5>
                  </div>

                  <div class="box-buttons">
                    <a class="btn-shop">Shop Now</a>
                  </div>
                </router-link>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-filters">
            <div class="box-filters">
              <div class="box-top">
                <h5 class="title">Filter products</h5>
                <a class="t-150 link-clear" @click="clearAll">Clear all</a>
              </div>

              <div class="box-form">
                <b-form @submit="onSubmit">
                  <b-form-group class="custom-f-group-s1 keywords-group">
                    <b-form-input
                      v-model="formFilter.keywords"
                      type="text"
                      placeholder="Search product"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group class="custom-f-group-s1">
                    <b-form-select v-model="formFilter.purpose">
                      <b-form-select-option :value="null">Select category</b-form-select-option>
                      <b-form-select-option value="1">Diving</b-form-select-option>
                      <b-form-select-option value="2">Sun protection</b-form-select-option>
                      <b-form-select-option value="3">Category 3</b-form-select-option>
                      <b-form-select-option value="4">Category 4</b-form-select-option>
                    </b-form-select>
                  </b-form-group>

                  <b-button type="submit" class="mt-4 btn-send">Apply</b-button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        { imageUrl: 'public/images/pages/products/product-1.jpg', name: 'Set snorkelling with snorket and diving googles' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Sprinter diving fins' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', name: 'Waterproof camera shockproof for swimming underwater 1050 HD' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', name: 'Diving Dive Lights AL-18LED' },
        { imageUrl: 'public/images/pages/products/product-1.jpg', name: 'Set snorkelling with snorket and diving googles' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Sprinter diving fins' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', name: 'Waterproof camera shockproof for swimming underwater 1050 HD' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', name: 'Diving Dive Lights AL-18LED' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Sprinter diving fins' },
      ],

      formFilter: {
        keywords: null,
        purpose: null,
      },

      minDate: null,
    }
  },

  methods: {
    clearAll() {
      this.formFilter = {
        keywords: null,
        purpose: null,
      };
    },

    onSubmit(event) {
      event.preventDefault();
    },
  },
}
</script>
