<template lang="html">
  <div id="blog-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-top">
          <h5 class="subtitle">BLOG</h5>
          <h2 class="title-s1">Where will you go next?</h2>
        </div>

        <div class="col-12 col-articles">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-xl-3 col-blog-s1" v-for="(a, aInx) in blog" :key="'aInx-'+aInx">
              <router-link class="box-link" to="/blog/5">
                <div class="box-image">
                  <img :src="a.imageUrl">
                </div>

                <div class="box-info">
                  <h4 class="title">{{ a.title }}</h4>

                  <div class="b-description">
                    <p>
                      Lorem ipsum dolor sit amet, con turadip iscingelit. In sed et donec purus viverra. Sit justo velit, eu sed.
                    </p>
                  </div>

                  <h6 class="author"><span>By</span> Adam Smith</h6>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      blog: [
        { imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Your dream vacation awaits: Top tours in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Must-see spots in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'The ultimate guide to touring Playa del Carmen' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Discover the magic of Mexico’s Rivera Maya' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Discover the magic of Mexico’s Rivera Maya' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'The ultimate guide to touring Playa del Carmen' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Must-see spots in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Your dream vacation awaits: Top tours in Cancun' },
      ],
    }
  }
}
</script>
