<template lang="html">
  <div id="contact-page">

    <section class="container main-section">
      <b-form @submit="onSubmit" class="row">
        <div class="col-12 text-center col-info">
          <h1 class="title-s1">Contact us!</h1>

          <p>
            Got stuck? Talk to a human!<br />
            It’s okay, we are happy to help!
          </p>

          <div class="box-extra">
            <router-link class="t-150 _btn" target="_blank" to="/faq"><i class="fa-light fa-seal-question"></i> FAQ</router-link>
            <a class="t-150 _btn" target="_blank" href="mailto:contact@kay.tours"><i class="fa-light fa-envelope"></i> Email</a>
            <a class="t-150 _btn" target="_blank" href="https://chat.whatsapp.com/HDTGsq1WZIm1khC5BlwgIq"><i class="fa-brands fa-whatsapp"></i> WhatsApp</a>
            <a class="t-150 _btn" target="_blank" href="https://msng.link/o/?529841432347=vi"><i class="fa-brands fa-viber"></i> Viber</a>
            <a class="t-150 _btn" target="_blank" href="https://tawk.to/chat/615b4b31d326717cb684b2fa/1fh69rebk"><i class="fa-sharp fa-regular fa-comment"></i> Chat</a>
          </div>
        </div>

        <div class="col-sm-6 col-lg-5 col-form">
          <b-form-group class="custom-f-group-s1"
            label="Name:">
            <b-form-input
              v-model="form.name"
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-sm-6 col-lg-5 col-form">
          <b-form-group class="custom-f-group-s1"
            label="Email:">
            <b-form-input
              v-model="form.email"
              type="email"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-sm-6 col-lg-5 col-form">
          <b-form-group class="custom-f-group-s1"
            label="Phone:">
            <b-form-input
              v-model="form.phone"
              type="text"
              minlength="10"
              placeholder="With country code e.g. +1 1234 5678"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-sm-6 col-lg-5 col-form">
          <b-form-group class="custom-f-group-s1"
            label="How many guest:">
            <b-form-input
              v-model="form.guest"
              type="number"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-sm-6 col-lg-5 col-form">
          <b-form-group class="custom-f-group-s1 date-group"
            label="Arrival date:">
            <b-form-datepicker v-model="form.arrival" placeholder="Select a date" :min="minDate" required></b-form-datepicker>
          </b-form-group>
        </div>

        <div class="col-sm-6 col-lg-5 col-form">
          <b-form-group class="custom-f-group-s1 date-group"
            label="Departure date:">
            <b-form-datepicker v-model="form.departure" placeholder="Select a date" :min="minDate" required></b-form-datepicker>
          </b-form-group>
        </div>

        <div class="col-lg-10 col-form">
          <b-form-group class="custom-f-group-s1 date-group"
            label="Hotel/Vacation rental:">
            <b-form-input
              v-model="form.rental"
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-10 col-form">
          <b-form-group class="custom-f-group-s1 date-group"
            label="Comments:">
            <b-form-textarea
              v-model="form.msg"
              placeholder=""
              rows="6"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-lg-10 text-right pt-2 col-button">
          <b-button type="submit" class="btn-s1 s-2">Send form</b-button>
        </div>
      </b-form>
    </section>

    <section class="map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23102.37882343716!2d-87.45067082854638!3d20.17530641665646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4fd1bbee05728f%3A0x6517aba82ac2c0b7!2sKay%20Tours%20Mexico%20%7C%20Tulum!5e0!3m2!1ses-419!2smx!4v1727970791662!5m2!1ses-419!2smx" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        msg: null,
      },

      minDate: null,
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/contact"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Mensaje enviado');
          this.form = {
            name: null,
            email: null,
            msg: null,
          };
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.guest, 'Error');
        this.makeToast('danger', error.response.data.arrival, 'Error');
        this.makeToast('danger', error.response.data.departure, 'Error');
        this.makeToast('danger', error.response.data.rental, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    },
  },

  beforeMount(){
    const now = new Date();
    this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  },
}
</script>
