<template lang="html">
  <div class="box-quantity-s1">
    <div class="b-form">
      <a class="btn-quantity" @click="reduce()"><i class="far fa-minus"></i></a>
      <input class="form-control" type='number' placeholder="0" :value="quantityValue" @input="$emit('update:quantityValue',$event.target.value)" required>
      <a class="btn-quantity" @click="increase()"><i class="far fa-plus"></i></a>
    </div>

    <div class="b-txt">
      <h6>
        {{ title }}
        <a v-b-tooltip.hover title="Provide drop off location details after payment"><i class="fa-solid fa-circle-info icon"></i></a>
      </h6>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quantityValue: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },

  data() {
    return {
      quantity: 0
    }
  },

  methods: {
    reduce() {
      this.quantity = (this.quantity >= 1) ? --this.quantity : 0;
      this.$emit('update:quantityValue',this.quantity)
    },

    increase() {
      ++this.quantity;
      this.$emit('update:quantityValue',this.quantity);
    },
  },
}
</script>
