<template lang="html">
  <div id="description-page">
    <loading-component-s1 v-if="loading"></loading-component-s1>

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <router-link to="/products">Products</router-link>
        <span class="line">/</span>
        <span>{{ product.name }}</span>
      </div>
    </section>

    <section class="container oversized-container description-section" id="product-description" v-if="isLoading == false">
      <div class="row">

        <div class="col-lg-6 col-gallery">
          <span class="bubble">-30%</span>

          <swiper :options="galleryOptions" v-if="showGallery">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <img :src="row">
                  <!-- <v-zoom :img="row" :width="'100%'"></v-zoom> -->
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>

        <b-form class="col-lg-5 col-description" @submit="addCart">
          <h1 class="name">{{ product.name }}</h1>
          <h6 class="sku"><strong>ID:</strong> {{ product.sku }}</h6>

          <!-- <h6 class="price discount" v-if="product.price_discount != null">
            <span>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price_orig) }}</span>
          </h6>
          <h4 class="price" v-if="product.price_discount != null">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price_discount) }}</h4>
          <h4 class="price" v-else>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h4> -->

          <div class="mt-3 txt-description"  v-html="product.short_description"></div>

          <h6 class="mt-3 subtitle" v-b-toggle="'collapse-2'">Description <small class="fas fa-chevron-down"></small></h6>
          <b-collapse class="mt-1" id="collapse-2">
            <div class="txt-description"  v-html="product.description"></div>
          </b-collapse>

          <div class="d-block mt-4 mb-2" v-if="product.documentUrl">
            <a class="btn btn-submit btn-contact" v-if="product.documentUrl" :href="product.documentUrl" target="_blank" rel="noopener noreferrer">Download technical sheet <i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
          </div>

          <!-- <h6 class="mt-2 mb-1 subtitle" v-if="product.tablaPrecios">Lista de precios</h6>
          <img class="img-fluid" :src="product.tablaPrecios"> -->

          <h6 class="mt-3 subtitle" v-if="product.delivery_time_custom && product.type == 'producto'">Delivery time</h6>
          <h4 class="delivery-time" v-if="product.delivery_time_custom && product.type == 'producto'">{{ product.delivery_time_custom }}</h4>

          <h4 class="delivery-time" v-if="product.type == 'servicio'">The provider of this service will coordinate with the buyer to define the start date and time of the service</h4>

          <div class="mt-4 table-responsive table-opts">
            <table class="table table-sm" id="tablemodels">
              <thead>
                <tr>
                  <th></th>
                  <th>Option</th>
                  <th>Price</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(model, indx) in modelos">
                  <td class="input-cell">
                    <label class="btn-action" :for="'model'+indx"></label>
                    <input class="fake-radio" type="radio" :id="'model'+indx" :name="model.id" :value="model.id" v-model="form.modelo" @click="changeModelid(indx,model.id)">
                  </td>
                  <td>{{model.name}}</td>
                  <td>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(model.price) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="stock != null">
            <h6 class="mt-4 subtitle" v-if="stock > 0">Available stock: <span class="txt-red">{{stock}}</span> </h6>
            <h6 class="mt-4 subtitle" v-else>Out of stock</h6>
          </div>

          <div class="col-quantity2 mt-2" v-if="stock > 0">
            <a class="form-control btn-q" @click="changeCantidad('-')">
              <i class="fas fa-minus"></i>
            </a>
            <b-form-input class="input-q" type="number" min="0" v-model="form.quantity" @keypress="isNumber($event)" @paste="noPaste" />
            <a class="form-control btn-q" @click="changeCantidad('+')">
              <i class="fas fa-plus"></i>
            </a>
          </div>

          <div class="d-block mt-4 mb-2">
            <b-button type="submit" class="my-2 my-xl-0 w-100 btn-s2">Add to cart</b-button>
          </div>
        </b-form>
      </div>
    </section>

    <section class="container oversized-container related-products-section" id="related-products" v-if="isLoading == false">
      <div class="row">
        <div class="col-lg-10 col-s-title">
          <h2 class="title-s2">Related products</h2>
        </div>

        <div class="col-lg-10 col-products">
          <div class="b-products">
            <swiper class="swiper" :options="relatedOptions">
              <swiper-slide v-for="(a, pInx) in products" :key="'pInx-'+pInx">
                <div class="col-12 px-0 col-product-s1">
                  <router-link class="box-link" to="/products/1">
                    <div class="box-image">
                      <img class="placed-backg" src="public/images/pages/products/product.png" v-bind:style="{ backgroundImage: 'url('+a.imageUrl+')' }">
                    </div>
                    <div class="box-info">
                      <h5 class="name">{{ a.name }}</h5>
                      <h5 class="price">$350.00</h5>
                    </div>

                    <div class="box-buttons">
                      <a class="btn-shop">Shop Now</a>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <sweet-modal blocking hide-close-button ref="modalloading">
      <div>Loading ...</div>
    </sweet-modal>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
      <div v-html="modal.msg"></div>
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
          <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Agregar mas productos</b-button>
          <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/cart')">Ir al carrito <i class="color-white fas fa-shopping-cart"></i></b-button>
      </div>
    </sweet-modal>

  </div>
</template>

<script>


import vZoom from 'vue-zoom'; // https://www.npmjs.com/package/vue-zoom
export default {
  components: {vZoom},

  data() {
    return {
      loading: false,

      id: null,
      showGallery: true,
      modalGallery: [],
      showModalGallery: false,

      form: {
        modelo: null,
        quantity: 1,
        color: null,
        models:[],
        modelo:null
      },

      product: {
        name: 'Product name',
        description: '',
        sku: 'D5A5049617B',
        price_discount: '69',
        price_orig: '80',
        price: '80',
        short_description: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        models:[],
      },

      category: [],

      products: [
        { imageUrl: 'public/images/pages/products/product-1.jpg', name: 'Set snorkelling with snorket and diving googles' },
        { imageUrl: 'public/images/pages/products/product-2.jpg', name: 'Sprinter diving fins' },
        { imageUrl: 'public/images/pages/products/product-3.jpg', name: 'Waterproof camera shockproof for swimming underwater 1050 HD' },
        { imageUrl: 'public/images/pages/products/product-4.jpg', name: 'Diving Dive Lights AL-18LED' },
      ],

      modelos: [
        { id: 1, modelo: 'Modelo1', name: 'Option 1', price: '80' },
        { id: 2, modelo: 'Modelo2', name: 'Option 2', price: '60' },
        { id: 3, modelo: 'Modelo3', name: 'Option 3', price: '90' },
      ],

      gallery: [
        'public/images/pages/products/product-4.jpg',
        'public/images/pages/products/product-4.jpg',
        'public/images/pages/products/product-4.jpg',
      ],

      relatedProducts: [],

      // isLoading: true,
      isLoading: false,
      stock: 5,

      modal:{
        msg:'',
        icon:'',
        block:false
      },

      // == Carrusel options ==
      galleryOptions: {
        loop: false,
        speed: 600,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }

        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // }
      },

      relatedOptions: {
        slidesPerView: 3,
        spaceBetween: 10,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: false
        },

        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: false
            }
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
        }
      },
      // == ==
    }
  },

  watch: {
        "$route.params.id" : function(v){
            //this.id = this.$route.params.id;
            this.id = this.$root._getURLID(this.$route.params.id);
            this.getProduct();
        },
        "form.modelo" : function(v){
            for (var i = 0; i < this.product.models.length; i++) {
                if(v == this.product.models[i].id){
                      this.stock = this.product.models[i].stock;
                      break;

                }
            }

        },
    },

  methods:{
    openModalGallery(galleryArray){
      this.modalGallery = galleryArray;

      this.$refs['modal-image'].show();

      setTimeout(()=>{
        this.showModalGallery = true;
      },700);
    },

    changeModelid(indx,sizes_id){
        this.price = this.product.sizes[indx]['price'];
        this.stock = this.product.sizes[indx]['stock'];
        this.form.sizes_id = sizes_id;
    },

    getProduct(){
      // this.isLoading = true; // DESCOMENTAR ESTO
      // this.$refs.modalloading.open(); // DESCOMENTAR ESTO
      // this.showGallery = false; // DESCOMENTAR ESTO
      axios.get(tools.url('/api/product/' + this.id)).then((response)=>{
        this.product = [];
        this.gallery = [];

        this.product = response.data;
        this.gallery = response.data.gallery;
        this.category = response.data.category;

        this.stock = this.product.stock;

        this.showGallery = true;
        this.getRelated(this.id);
        this.$refs.modalloading.close();
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
        this.isLoading = false;
      });
    },

    getRelated(id){
      axios.get(tools.url('/api/products/related/'+id)).then((response)=>{
        this.products = response.data;

      }).catch((error)=>{
        console.log(error);
      })
    },

    addCart(evt){
      evt.preventDefault();
      var cart = [];

      if (this.product.models.length > 0) {
          if (this.form.modelo == null) {
              alert('Select a model or size');
              return;
          }
          else{
              if(localStorage.cart){
                  cart = JSON.parse(localStorage.getItem('cart'));

                  for(var i = 0; i < cart.length ; i++)
                  {
                      if(cart[i].id == this.id && cart[i].modelo == this.form.modelo)
                      {
                          var suma = cart[i].quantity + this.form.quantity;
                          cart[i].quantity = suma <= this.stock ? suma : this.stock;
                          encontrado = true;
                          break;
                      }
                  }

                  if(!encontrado)
                  {
                      cart.push({ id:this.id, quantity:this.form.quantity, modelo: this.form.modelo, color: this.form.color, imageUrl: this.gallery[0] });
                  }
              }
              else
              {
                  cart.push({ id:this.id, quantity:this.form.quantity, modelo: this.form.modelo, imageUrl: this.gallery[0] });
              }
          }
      }
      else{
          if(localStorage.cart){
                  cart = JSON.parse(localStorage.getItem('cart'));

                  for(var i = 0; i < cart.length ; i++)
                  {
                      if(cart[i].id == this.id && cart[i].modelo == this.form.modelo)
                      {
                          var suma = cart[i].quantity + this.form.quantity;
                          cart[i].quantity = suma <= this.stock ? suma : this.stock;
                          encontrado = true;
                          break;
                      }
                  }

                  if(!encontrado)
                  {
                      cart.push({ id:this.id, quantity:this.form.quantity, modelo: this.form.modelo, imageUrl: this.gallery[0] });
                  }
              }
              else
              {
                  cart.push({ id:this.id, quantity:this.form.quantity, modelo: this.form.modelo, imageUrl: this.gallery[0] });
              }
      }

      var encontrado = false;


      localStorage.setItem("cart",JSON.stringify(cart));
      this.$root.cartCount = cart.length;
      //this.toast();
      this.modal.msg = 'The product <b>'+this.product.name+'</b> was added to the cart';
      this.modal.icon = 'success';
      this.$refs.modal.open();
    },
    noPaste(evt){
      event.preventDefault();
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    changeCantidad($type){
      this.form.quantity = parseInt(this.form.quantity);
      if ($type == '-') {
        this.form.quantity = (this.form.quantity > 1) ? --this.form.quantity : 1;
      }
      else{
        this.form.quantity = (this.form.quantity < 100) ? ++this.form.quantity : 100;
      }
    },
  },

   mounted(){
    // this.id = this.$route.params.id;
    // this.id = this.$root._getURLID(this.$route.params.id);
    // this.getProduct();

    this.loading = true;
    setTimeout(()=> {
      this.loading = false;
    }, 2000);
  }
}
</script>
