<template lang="html">
  <div id="home-page">

    <section class="banners-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners" :key="'bhInx-'+bhInx">
          <img :src="b">
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(bm, bhInx) in bannersMovil" :key="'bhInx-'+bhInx">
          <img :src="bm">
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="benefits-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-4 col-comments">
            <div class="box-comments">
              <div class="inside">
                <swiper class="swiper" :options="commentsOptions">
                  <swiper-slide>
                    <div class="box-comment">
                      <div class="b-stars">
                        <b-form-rating inline value="4" readonly></b-form-rating>
                      </div>

                      <div class="b-title">
                        <h6>❝Best vacations ever❞</h6>
                      </div>

                      <div class="b-description">
                        <div class="b">
                          <p>
                            Et eligendi expedita. Accusantium qui est elus nemo eaque dolore necessitatibus voluptatem.<br />
                            Ut accusamus provident beatae dolorum amet. Omnis sapiente aut saepe aliquam doloribus eaque. lure sit sed animi molestiae impedit.
                          </p>
                        </div>
                      </div>

                      <div class="b-user">
                        <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/photo.jpg)' }"></div>
                        <div class="text">
                          <h6>From: Sophia Jenkins</h6>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>Slide 2</swiper-slide>
                  <swiper-slide>Slide 3</swiper-slide>
                  <swiper-slide>Slide 4</swiper-slide>
                  <swiper-slide>Slide 5</swiper-slide>
                  <swiper-slide>Slide 6</swiper-slide>
                  <swiper-slide>Slide 7</swiper-slide>
                  <swiper-slide>Slide 8</swiper-slide>
                  <swiper-slide>Slide 9</swiper-slide>
                  <swiper-slide>Slide 10</swiper-slide>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 col-list">
            <div class="box-content">
              <div class="b-title bg-1">
                <h5 class="title">Benefits</h5>
              </div>

              <div class="b-content">
                <div class="inside">
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> Pick up at your hotel</p>
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> Free cancelations</p>
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> Full flexibility</p>
                  <p><i class="fa-solid fa-check icon"></i> Reserve now, Pay later</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 col-list">
            <div class="box-content">
              <div class="b-title bg-2">
                <h5 class="title">Why Choose Us?</h5>
              </div>

              <div class="b-content">
                <div class="inside">
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> Exclusive transportation</p>
                  <p class="mb-1"><i class="fa-solid fa-check icon"></i> No risk</p>
                  <p><i class="fa-solid fa-check icon"></i> Reserve now, pay later</p>

                  <p class="pl-3 mt-2 mt-lg-3">
                    <router-link class="ml-3 ml-xl-4 link-more t-150" to=""><u>See More</u></router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="tours-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-title">
            <h2 class="title-s1">Our trending tour<br />packages</h2>
          </div>

          <div class="col-lg-4 col-buttons">
            <router-link to="">View all tours</router-link>
          </div>
        </div>

        <div class="row row-tours">
          <div class="col-12">
            <swiper class="swiper" :options="toursOptions">
              <swiper-slide v-for="(tour, thInx) in tours" :key="'thInx-'+thInx">
                <div class="col-12 px-0 col-tour-s1">
                  <div class="box-link">
                    <div class="box-image">
                      <img :src="tour.imageUrl">
                    </div>

                    <div class="box-info">
                      <h6 class="days"><i class="fa-light fa-calendar icon"></i> 8 Days</h6>

                      <h4 class="name">{{ tour.name }}</h4>

                      <div class="b-stars">
                        <b-form-rating inline value="4"></b-form-rating>
                      </div>

                      <div class="b-price">
                        <h5 class="price">{{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(tour.price) }} $</h5>
                        <h5 class="price old-price">{{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(tour.oldPrice) }} $</h5>
                      </div>

                      <div class="b-description">
                        <p>
                          Nam exercitationem commodi et ducimus quia in dolore animi sit mollitia amet id quod eligendi. Et labore harum
                          non nobis ipsum eum molestias mollitia et corporis
                          praesentium a laudantium internos.
                        </p>
                      </div>

                      <a class="btn-explore">Explore Now</a>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="faq-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-7 col-content">
            <h2 class="title-s1">F.A.Q.</h2>

            <div class="accordion" role="tablist">
              <b-card no-body v-for="(qa, qaInx) in faqs" :key="'qaInx-'+qaInx">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <a block v-b-toggle="'accordion-'+qaInx">{{ qa.q }}</a>
                </b-card-header>
                <b-collapse :visible="(qaInx === 0) ? true : false"  :id="'accordion-'+qaInx" accordion="faqs-accordion" role="tabpanel">
                  <b-card-body>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="col-lg-5 col-image">
            <img src="public/images/pages/home/faq-image.jpg">
          </div>
        </div>
      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-title">
            <h5 class="subtitle">BLOG</h5>
            <h2 class="title-s1">Where will you go next?</h2>
          </div>
        </div>

        <div class="row row-carousel">
          <div class="col-12">
            <swiper class="swiper" :options="blogOptions">
              <swiper-slide v-for="(a, ahInx) in blog" :key="'ahInx-'+ahInx">
                <div class="col-12 px-0 col-blog-s1">
                  <div class="box-link">
                    <div class="box-image">
                      <img :src="a.imageUrl">
                    </div>

                    <div class="box-info">
                      <h4 class="title">{{ a.title }}</h4>

                      <div class="b-description">
                        <p>
                          Lorem ipsum dolor sit amet, con turadip iscingelit. In sed et donec purus viverra. Sit justo velit, eu sed.
                        </p>
                      </div>

                      <h6 class="author"><span>By</span> Adam Smith</h6>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="testimonials-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-title">
            <h5 class="subtitle">TESTIMONIALS</h5>
            <h2 class="title-s1">See what our clients say<br />About us</h2>
          </div>

          <div class="col-12 col-lg-8 mx-auto col-testimonials">
            <swiper class="swiper" :options="testimonialsOptions">
              <swiper-slide v-for="(t, tshInx) in 3" :key="'tshInx-'+tshInx">
                <div class="box-testimonial">
                  <div class="box">
                    <div class="b-photo">
                      <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/testimonial-photo.jpg)' }"></div>
                    </div>

                    <div class="b-text">
                      <div class="inside">
                        <p>
                          <strong class="apos">“</strong>Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat. Id sunt provident quo possimus impedit vel doloreque obcaecati qui ullam consectetur et ipsum omnis.<strong class="apos">”</strong>
                        </p>
                        <h6 class="author">Christine Beckam - Designer</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="newsletter-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-form">
            <h2 class="title-s1">Stay updated on the best of<br />Mexico’s Caribbean Coast</h2>
            <h5 class="subtitle">Join our newsletter</h5>

            <div class="box-form">
              <b-form @submit="onSubmit">
                <b-form-group>
                  <b-form-input
                    v-model="formNewsletter.email"
                    type="email"
                    placeholder="Your email address"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" class="btn-send">Subscribe</b-button>
              </b-form>

              <h6 class="note">* Will send you weekly updates for your better tour packages.</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: [
        'public/images/pages/home/banner-1.jpg',
        'public/images/pages/home/banner-2.jpg',
        'public/images/pages/home/banner-3.jpg',
      ],
      bannersMovil: [
        'public/images/pages/home/banner-1-m.jpg',
        'public/images/pages/home/banner-2-m.jpg',
        'public/images/pages/home/banner-3-m.jpg',
      ],

      tours: [
        { imageUrl: 'public/images/pages/tours/tour-1.jpg', name: 'Cancun', price: 1000, oldPrice: 1200 },
        { imageUrl: 'public/images/pages/tours/tour-2.jpg', name: 'Chichen Itza', price: 1223, oldPrice: 1400 },
        { imageUrl: 'public/images/pages/tours/tour-3.jpg', name: 'Tulum', price: 1200, oldPrice: 1300 },
        { imageUrl: 'public/images/pages/tours/tour-4.jpg', name: 'Playa de Carmen', price: 1100, oldPrice: 1200 },
        { imageUrl: 'public/images/pages/tours/tour-2.jpg', name: 'Chichen Itza', price: 1223, oldPrice: 1400 },
      ],

      faqs: [
        {
          q: 'Is Chichen Itza open despite COVID-19?',
        },
        {
          q: 'What are the best tours in Playa del Carmen?',
        },
        {
          q: 'Do you need to book Chichen Itza tickets in advance?',
        },
        {
          q: 'What is the best way to see Chichen Itza?',
        },
      ],

      blog: [
        { imageUrl: 'public/images/pages/blog/article-1.jpg', title: 'Your dream vacation awaits: Top tours in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-2.jpg', title: 'Must-see spots in Cancun' },
        { imageUrl: 'public/images/pages/blog/article-3.jpg', title: 'The ultimate guide to touring Playa del Carmen' },
        { imageUrl: 'public/images/pages/blog/article-4.jpg', title: 'Discover the magic of Mexico’s Rivera Maya' },
      ],

      formNewsletter: {
        email: null,
      },

      // == Carousels ==
      bannersOptions: {
        effect: 'fade',
        speed: 700,
        loop: true,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      },
      commentsOptions: {
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      toursOptions: {
        slidesPerView: 4,
        spaceBetween: 10,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: false
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: false
            }
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
        }
      },
      blogOptions: {
        slidesPerView: 4,
        spaceBetween: 10,

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: false
        },

        breakpoints: {
          1400: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          1200: {
            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: false
            }
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10,

            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true
            }
          },
        }
      },
      testimonialsOptions: {
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  }
}
</script>
