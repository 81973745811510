import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', name: 'homePage', component: require(page+'home.vue').default },

      { path: '/tours', component: require(page+'tours/index.vue').default, meta:{ title:"Tours"} },
	    { path: '/tour/:id', name: 'tourDescrPage', component: require(page+'tours/description.vue').default, meta:{ title:"Tour"} },

      { path: '/products', component: require(page+'products/index.vue').default, meta:{ title:"Products"} },
      { path: '/products/:id', component: require(page+'products/description.vue').default, meta:{ title:"Products"} },

      { path: '/booking', component: require(page+'cart/booking.vue').default, meta:{ title:"Book now"} },
      { path: '/booking/thank-you', component: require(page+'cart/thank-you.vue').default, meta:{ title:"Thank you"} },
      { path: '/cart/:step?', component: require(page+'cart/index.vue').default, meta:{ title:"My cart" }},

      { path: '/blog', component: require(page+'blog/index.vue').default, meta:{ title:"Blog"} },
      { path: '/blog/:id', component: require(page+'blog/article.vue').default, meta:{ title:"Blog"} },

      { path: '/services', component: require(page+'services/index.vue').default, meta:{ title:"Services"} },
      { path: '/reviews', component: require(page+'reviews/index.vue').default, meta:{ title:"Reviews"} },
      { path: '/faq', component: require(page+'faqs/index.vue').default, meta:{ title:"FAQ"} },
      { path: '/about-kay-tours', component: require(page+'about-us/index.vue').default, meta:{ title:"About us"} },
      { path: '/contact', component: require(page+'contact/index.vue').default, meta:{ title:"Contact"} },

      { path: '/terms-and-conditions', component: require(page+'text-pages/terms.vue').default, meta:{ title:"Terms & Conditions"} },
      { path: '/privacy-policy', component: require(page+'text-pages/privacy.vue').default, meta:{ title:"Privacy Policy"} },
      { path: '/medical-form', component: require(page+'text-pages/medical.vue').default, meta:{ title:"Confidential medical form"} },

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Log in"}},
      { path: '/reset-password', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Reset password"}},
      { path: '/register', component: require(page+'user-access/register.vue').default, meta:{title:"Register"}},

      { path: '/user', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'My data' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Change Password' }
  			},
        {
            path: 'historial-compras',
            component: require(page+'usuario-cuenta/historial-compras.vue').default,
            meta:{ title: 'My orders' }
        },
        {
            path: 'pedido/:id',
            component: require(page+'usuario-cuenta/orderdetails.vue').default,
            meta:{ title: 'My orders' }
        },
  	  	]
  		},
    ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Kay Tours", filter: (title)=>{ return title+" - Kay Tours"; } }
);

// export {routes};
export default MyRouter;
