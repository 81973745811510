<template lang="html">
  <div id="reviews-page">

    <ReviewsComponent></ReviewsComponent>

  </div>
</template>

<script>
import ReviewsComponent from './reviews-component.vue'
export default {
  components: {
    ReviewsComponent
  },
}
</script>
