<template lang="html">
  <div class="container" id="text-page">
    <h1 class="page-title txt-black">Confidential medical form</h1>

    <br />
    <h5>General information</h5>
    <p>
      The information you provide to Kay Tours Mexico in this form will be held in the strictest confidence, and will be used only to the extent necessary to provide necessary emergency medical care and/or evaluate fitness for travel. Please note that this may include transmitting your data overseas to any countries to which you may be visiting, or to a Kay Tours Mexico global office, but only as required. The collection, use, and disclosure of your personal information is governed by the Kay Tours Mexico Privacy Policy, which can be accessed any time at http://www.myprivate.tours/privacy
    </p>

    <br />
    <h6>Who should complete this form? </h6>
    <p>
      All travellers must agree with our Terms & Conditions. If you have a pre-existing medical condition you are required to complete this confidential medical form. The more information Kay Tours Mexico has, the more we may assist in the unlikely event of an emergency or provide other medical assistance. Please note Kay Tours Mexico will assess the information contained in this form, and reserve the right to ask for a physician assessment for any passenger. You should always consult with your physician and anyone else familiar with your medical history and needs before embarking on any adventure travel. Please ensure that you have confirmed with a medical professional that you are medically fit to embark on the travel you have booked.
    </p>

    <br />
    <h6>Why do I need to complete this form?</h6>
    <p>
      Our tours travel to remote areas where limited or no sophisticated medical facilities exist. A medical emergency situation is extremely unlikely; however, should it arise we are armed with the necessary information to help you. Generally, our tours are intended for travellers in reasonably good health for their safety, along with that of their fellow travellers. You must provide complete, accurate, and up-to-date information on this form in order to allow Kay Tours Mexico to safely accommodate you. Kay Tours Mexico reserves the right to deny participation and/or passage to any passenger who is unable to be carried safely, in the opinion of the CEO or guide responsible for you. If you do not disclose a condition, infirmity, injury, or ailment herein and are subsequently deemed to be unfit for expedition travel due in whole or in part to such condition, infirmity, injury or ailment, Kay Tours Mexico shall have the right to remove you from the tour with no refund or compensation payable. If there are any changes to your physical/medical condition or otherwise to your responses below after your submission of the form to Kay Tours Mexico, you must notify Kay Tours Mexico immediately of that change. Kay Tours Mexico reserves the right to request an up-to-date certification from a licensed physician in the event of such a change. If the information contained on this form is found to not be accurate as of your date of travel and you have not provided Kay Tours Mexico with notice of such change, you may be removed from the tour with no refund or compensation payable. Information provided in this form must be supplied at maximum 12 months prior to first date of travel.
    </p>

    <br />
    <h6>What happens if I don’t complete this form? </h6>
    <p>
      In the event you have made a booking with Kay Tours Mexico and subsequently are unable or refuse to complete this medical form for any reason by the final payment date as specified in our terms and conditions, Kay Tours Mexico reserves the right to consider your booking cancelled as of that day and applicable cancellation penalties will apply.
    </p>

    <br />
    <h6>How do I complete this form? </h6>
    <p>
      It is very important for your own health and safety that you complete all questions fully and truthfully. In the event of a medical emergency, the information you have provided could be crucial.
    </p>

    <br />
    <p>
      Please contact us, should you have any questions: +52 1 984 43 42 47 or email: contact@kay.tours
    </p>
  </div>
</template>

<script>
export default {
}
</script>
