<template lang="html">
  <header id="header">
    <div class="header-menu transparent-header">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img class="logo" src="public/images/logo.png">
            <img class="logo-white" src="public/images/logo-white.png">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <i class="fa-sharp fa-regular fa-bars"></i>
          </b-navbar-toggle>

          <b-collapse class="nav-collapsible" id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="nav-w-dropdown" to="">Tours</b-nav-item>
              <b-nav-item class="nav-w-dropdown" to="">Services</b-nav-item>
              <b-nav-item to="">Reviews</b-nav-item>
              <b-nav-item-dropdown right>
                <template #button-content>
                  <span>Eng</span>
                </template>
                <b-dropdown-item><img class="flag" src="public/images/flag-usa.jpg"> ENGLISH</b-dropdown-item>
                <b-dropdown-item><img class="flag" src="public/images/flag-german.jpg"> GERMAN</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item to=""><span class="d-lg-none">Contact</span> <i class="fa-light fa-envelope"></i></b-nav-item>
              <b-nav-item to="">Cart <i class="fa-light fa-cart-shopping"></i></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  methods: {
  }
}
</script>
