<template lang="html">
  <b-form id="booking-page" @submit="onSubmit">

    <section class="container oversized-container breadcrumb-section">
      <router-link class="t-150 link-back" to=""><i class="fas fa-chevron-left icon"></i> EXCLUSIVE PRIVATE TOUR AT COZUMEL</router-link>
    </section>

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-lg-8 col-left">
          <!-- 1. Check availability -->
          <div class="box-availability">
            <h2 class="mb-4 title-s1">1. Check availability</h2>

            <div class="mb-5 box-tickets">
              <h5 class="mb-2 title-1">Select ticket</h5>

              <div class="box-quantity">
                <span class="txt">Adults</span>

                <div class="b-form">
                  <a class="btn-quantity" @click="form.adults = (form.adults >= 1) ? --form.adults : 0"><i class="far fa-minus"></i></a>
                  <b-form-input
                    v-model="form.adults"
                    type="number"
                    placeholder="0"
                    required
                  ></b-form-input>
                  <a class="btn-quantity" @click="form.adults++"><i class="far fa-plus"></i></a>
                </div>
              </div>

              <div class="box-quantity">
                <span class="txt">Kids</span>

                <div class="b-form">
                  <a class="btn-quantity" @click="form.kids = (form.kids >= 1) ? --form.kids : 0"><i class="far fa-minus"></i></a>
                  <b-form-input
                    v-model="form.kids"
                    type="number"
                    placeholder="0"
                    required
                  ></b-form-input>
                  <a class="btn-quantity" @click="form.kids++"><i class="far fa-plus"></i></a>
                </div>
              </div>
            </div>

            <div class="mb-5 box-date">
              <div class="content-lg">
                <h5 class="mb-3 title-1">Select your date</h5>

                <div class="row b-date">
                  <div class="col-lg-6 col-xl-5 col-selected-date">
                    <b-form-group class="custom-f-group-s1 date-group">
                      <b-form-datepicker v-model="form.date" placeholder="Select date"></b-form-datepicker>
                    </b-form-group>
                  </div>

                  <div class="col-lg-6 col-xl-5 col-button">
                    <a class="btn btn-s1 s-2">Check availability</a>
                  </div>

                  <div class="col-12 col-calendar">
                    <div class="box-VCalendar">
                      <v-date-picker
                        is-expanded
                        v-model='form.date'
                        :columns="$screens({ default: 1, lg: 2 })"
                        :min-date='new Date()'
                        />
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="box-time">
              <div class="content-lg">
                <h5 class="mb-3 title-1">Select pickup time</h5>

                <div class="row">
                  <div class="col-lg-5 col-selected-time">
                    <b-form-group class="custom-f-group-s1">
                      <b-form-select v-model="form.time">
                        <b-form-select-option :value="null">Select hour</b-form-select-option>
                        <b-form-select-option value="1">7:00 A.M.</b-form-select-option>
                        <b-form-select-option value="2">7:30 A.M.</b-form-select-option>
                        <b-form-select-option value="3">8:00 A.M.</b-form-select-option>
                        <b-form-select-option value="4">8:30 A.M.</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- 2. Secure your reservation -->
          <div class="box-user-info">
            <div class="content-lg">
              <h2 class="mb-3 title-s1">2. Secure your reservation</h2>
              <div class="box-description">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                </p>
              </div>

              <div class="box-form">
                <h5 class="mb-3 title-2">RESERVATION NAME</h5>

                <div class="row">
                  <div class="col-lg-6 col-xl-5">
                    <b-form-group class="custom-f-group-s1"
                      label="First name">
                      <b-form-input
                        v-model="form.name"
                        type="text"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-lg-6 col-xl-5">
                    <b-form-group class="custom-f-group-s1"
                      label="Last name">
                      <b-form-input
                        v-model="form.lastName"
                        type="text"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-lg-12 col-xl-10">
                    <b-form-group class="custom-f-group-s1"
                      label="Email address">
                      <b-form-input
                        v-model="form.email"
                        type="email"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-5 pr-0 pr-sm-3 col-sm-4 col-lg-5 col-xl-3">
                    <b-form-group class="custom-f-group-s1"
                      label="Mobile number">
                      <b-form-select v-model="form.countryNumber">
                        <b-form-select-option :value="null"></b-form-select-option>
                        <b-form-select-option value="a">Canada (+1)</b-form-select-option>
                        <b-form-select-option value="b">México (+52)</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-7 col-sm-8 col-lg-7">
                    <b-form-group class="custom-f-group-s1" label=" ">
                      <b-form-input
                        v-model="form.phone"
                        type="text"
                        maxlength="10"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- 3. Payment options -->
          <div class="box-payments">
            <div class="content-lg">
              <h2 class="mb-3 title-s1">3. Payment options</h2>
              <div class="box-description">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                </p>
              </div>

              <div class="box-options">
                <div class="b-options" v-bind:class="{ active : form.paymentOption == 1 }" @click="form.paymentOption = 1">Debit/Credit card</div>
                <div class="b-options" v-bind:class="{ active : form.paymentOption == 2 }" @click="form.paymentOption = 2">Paypal</div>
                <div class="b-options" v-bind:class="{ active : form.paymentOption == 3 }" @click="form.paymentOption = 3">Bank transfer</div>
              </div>

              <div class="box-selected-option">
                <div class="row">
                  <div class="col-12 mb-4">
                    <img src="public/images/payments.png">
                  </div>

                  <div class="col-lg-12">
                    <b-form-group class="custom-f-group-s1"
                      label="Name on card">
                      <b-form-input
                        v-model="form.cardName"
                        type="text"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-lg-12">
                    <b-form-group class="custom-f-group-s1"
                      label="Debit/Credit card number">
                      <b-form-input
                        v-model="form.cardNumber"
                        type="number"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-7 col-sm-5 pr-0 pr-sm-3">
                    <b-form-group class="custom-f-group-s1"
                      label="Expiration date">
                      <b-form-select v-model="form.cardMonth" required>
                        <b-form-select-option :value="null">Month</b-form-select-option>
                        <b-form-select-option value="1">January</b-form-select-option>
                        <b-form-select-option value="2">February</b-form-select-option>
                        <b-form-select-option value="3">March</b-form-select-option>
                        <b-form-select-option value="4">April</b-form-select-option>
                        <b-form-select-option value="5">May</b-form-select-option>
                        <b-form-select-option value="6">June</b-form-select-option>
                        <b-form-select-option value="7">July</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-5 col-sm-5">
                    <b-form-group class="custom-f-group-s1"
                      label=" ">
                      <b-form-select v-model="form.cardYear" required>
                        <b-form-select-option :value="null">Year</b-form-select-option>
                        <b-form-select-option :value="currentYear + yInx" v-for="(y, yInx) in 11" :key="'yInx-'+yInx">{{ currentYear + yInx }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-5 col-sm-5 col-lg-6 pr-0 pr-sm-3">
                    <b-form-group class="custom-f-group-s1"
                      label="Security code">
                      <b-form-input
                        v-model="form.cardCode"
                        type="number"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-7 col-sm-5 col-lg-6">
                    <b-form-group class="custom-f-group-s1"
                      label="Billing zip code">
                      <b-form-input
                        v-model="form.cardZip"
                        type="number"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- 4. Book now -->
          <div class="box-book-now">
            <div class="row">
              <div class="col-lg-7 col-important-note">
                <div class="b-note">
                  <h6 class="title">Important information about your booking</h6>

                  <ol>
                    <li>This rate is non-refundable. If you change or cancel your booking you will not get a refund or credit to use for a future stay.</li>
                    <li>Stay extensions will require a new reservation.</li>
                    <li>﻿﻿﻿Front desk staff will greet guests on arrival.</li>
                    <li>﻿﻿﻿No refunds will be issued for late check-in or early check-out.</li>
                  </ol>
                </div>
              </div>

              <div class="col-lg-5 col-finish">
                <div class="row">
                  <div class="col-12 align-self-start col-note">
                    <div class="note">
                      <i class="fa-solid fa-lock-keyhole icon"></i> We use secure transmission and encrypted storage to protect your personal information.
                    </div>
                  </div>

                  <div class="col-12 align-self-end col-button">
                    <b-button type="submit" class="btn-s2">Book now!</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
        </div>

        <div class="col-lg-4 col-xl-3 offset-xl-1 col-right">
          <div class="box-details">
            <div class="box-title">
              <h5>Price details</h5>
            </div>

            <div class="box-information">
              <h6 class="detail-title">Exclusive private tour at Cozumel</h6>

              <div class="row mb-2 row-columns">
                <div class="col-7 col-lg-8 col-label">
                  2 adults
                </div>

                <div class="col-5 col-lg-4 col-result">
                  <span class="mr-1">$</span> 120.32
                </div>
              </div>

              <div class="row row-columns">
                <div class="col-7 col-lg-8 col-label">
                  Tax and service fees
                </div>

                <div class="col-5 col-lg-4 col-result">
                  <span class="mr-1">$</span> 8.32
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-line">
                  <hr class="line-1" />
                </div>
              </div>

              <div class="row row-columns row-total-price">
                <div class="col-7 col-lg-8 col-label">
                  Total
                </div>

                <div class="col-5 col-lg-4 col-result">
                  $130.00
                </div>
              </div>

              <h6 class="mt-2 mb-4 txt-coupon">Use a coupon, credit or promotional code</h6>

              <div class="box-coupon">
                <div class="col-12 col-label">
                  <h6>Coupon code</h6>
                </div>

                <div class="col col-input">
                  <b-form-group class="custom-f-group-s1">
                    <b-form-input
                      v-model="form.name"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col col-button">
                  <b-button type="button" class="btn-s2 no-min-w">Apply coupon</b-button>
                </div>
              </div>

              <div class="box-text">
                <p>
                  By clicking the button below, I acknowledge that I have reviewed the Privacy Statement and have reviewd and accept the Rules and Restrictions and Terms of Use.
                </p>
              </div>

              <div class="box-button">
                <b-button type="submit" class="btn-s2">Book now!</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </b-form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        adults: 0,
        kids: 0,
        date: null,
        time: null,
        name: null,
        lastName: null,
        email: null,
        countryNumber: null,
        phone: null,
        paymentOption: 1,
      },

      currentYear: 0,
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
  },

  beforeMount() {
    this.currentYear = new Date().getFullYear();
  }
}
</script>
